import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';
import CallIcon from '@material-ui/icons/Call';


import styles from "assets/jss/material-kit-react/views/landingPageSections/contactStyle.js";

const useStyles = makeStyles(styles);

export default function ContactSection() {
  const classes = useStyles();
  const LocationPin = ({ text }) => (
    <div className={classes.pin}>
      <RoomIcon className={classes.pinIcon} />
      <p className={classes.pinText}>{text}</p>
    </div>
  )
  const location = {
    address: '31-70 College Point Blvd, Flushing, NY 11354',
    lat: 40.76783666088593, 
    lng: -73.84085540221498
  }
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={12}>
          <h2 className={classes.title}>Contact Us</h2>
          <h4 className={classes.description}>
            To request a quote or schedule an appointment call us at <b>(718)672-9533</b> or at <b>(631)703-4141</b>.<br/>
          </h4>
          <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={6}>
            <GridContainer>
              <GridItem xs={12} className={classes.description}>
                <h4 className={classes.pinText}>
                  <RoomIcon style={{fontSize: '3rem'}}/>31-70 College Point Blvd, Flushing NY, 11354
                </h4>
              </GridItem>
              <GridItem xs={12} className={classes.description}>
                <h4 className={classes.pinText}>
                  <CallIcon style={{fontSize: '3rem'}}/>(718)672-9533
                </h4>
              </GridItem>
              <GridItem xs={12} className={classes.description}>
                <h4 className={classes.pinText}>
                  <CallIcon style={{fontSize: '3rem'}}/>(631)703-4141
                </h4>
              </GridItem>
              <GridItem xs={12}>
                <h3 className={classes.title}>
                  Business Hours
                </h3>
              </GridItem>
              <GridItem xs={12} m={6}>
                <h4 className={classes.description}>
                  <b>Monday - Saturday</b>
                </h4>
              </GridItem>
              <GridItem xs={12} m={6}>
                <h4 className={classes.description}>
                  8 AM - 6 PM
                </h4>
              </GridItem>
              <GridItem xs={12} m={6}>
                <h4 className={classes.description}>
                  <b>Sunday</b>
                </h4>
              </GridItem>
              <GridItem xs={12} m={6}>
                <h4 className={classes.description}>
                  CLOSED
                </h4>
              </GridItem>
            </GridContainer>
            </GridItem>
            <GridItem cs={12} sm={12} md={6}>
              <div className={classes.googleMap}>
                <GoogleMapReact
                bootstrapURLKeys={{key: 'AIzaSyCEHJcs2bI1UGMxtKTSWIx2P_vJWzl0wNk'}}
                defaultCenter={location}
                defaultZoom={17}
                >
                  <LocationPin 
                    lat={location.lat}
                    lng={location.lng}
                    text={location.address}
                    />
                </GoogleMapReact>
              </div>
            </GridItem>
          </GridContainer>
          
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import ContactPage from "views/ContactPage/ContactPage.js";
import GalleryPage from "views/GalleryPage/GalleryPage.js";
import ScrollToTop from "components/ScrollToTop/ScrollToTop.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <ScrollToTop>
      <Switch>
        <Route path="/contact" component={ContactPage} />
        <Route path="/gallery" component={GalleryPage} />
        <Route path="/" component={LandingPage} />
      </Switch>
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoImage from "components/InfoImage/infoImage.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Over a quarter of a century of experience</h2>
          <h5 className={classes.description}>
          We started repairing cars in the early 90s and eventually opened our own shop. You may remember us as Union Auto Body located on Willets Point Blvd next to Citi Field, known at that time as Shea Stadium where we have served all your auto repair needs since 1998.
          We have since moved to 31-70 College Point Blvd, Flushing, New York 11354 starting in 2013.<br />
          We do Body Work, Paint Jobs, Straighten Chassis, and Air Conditioning repair(April - August only).
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoImage
              title="Body Work"
              description=""
              img={require("assets/img/body.jpg")}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoImage
              title="Painting"
              description=""
              img={require("assets/img/paint.jpg")}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoImage
              title="A/C Servicing"
              description="April-August Only"
              img={require("assets/img/other.jpg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

import { title } from "assets/jss/material-kit-react.js";

const galleryStyle = {
    section: {
        padding: "70px 0",
        textAlign: "center"
      },
      title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none"
      },
      description: {
        color: "#999"
      }
};

export default galleryStyle;

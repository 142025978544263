/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import Button from "components/CustomButtons/Button.js";
import RoomIcon from '@material-ui/icons/Room';
import CallIcon from '@material-ui/icons/Call';

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <GridContainer>
            <GridItem xs={12}>
              <RoomIcon />31-70 College Point Blvd, Flushing, NY, 11354
            </GridItem>
            <GridItem xs={12}>
              <CallIcon />(718)672-9533
              <CallIcon />(631)703-4141
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.right}>
          <GridContainer>
            <GridItem xs={12}>
              &copy; {1900 + new Date().getYear()}, 
              New Union Auto Repair Inc.
            </GridItem>
            <GridItem xs={6}>
              <Button
                href="https://www.yelp.com/biz/new-union-auto-repair-inc-queens-2"
                target="_blank"
                color="transparent"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-yelp"} />
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button
                color="transparent"
                href="https://www.facebook.com/newunionautorepairinc/"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + " fab fa-facebook"} />
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};

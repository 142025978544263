import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ImageCaption from "components/ImageCaption/ImageCaption.js";
import styles from "assets/jss/material-kit-react/views/galleryPageSections/galleryStyle.js";

const useStyles = makeStyles(styles);

export default function GallerySection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
          <GridItem xs ={12}>
          <h2 className={classes.title}>Auto Body Work</h2>
          <h5 className={classes.description}>
            We mainly do auto body work. All the cars below are cars we have fixed. And if your car has similar damage, we can definitely fix it for you.
          </h5>
          </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/beforeVW.jpg")}></ImageCaption>
            </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/aftervw.jpg")}></ImageCaption>
            </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/Before Cherokee.jpg")}></ImageCaption>
            </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/after cherokee.jpg")}></ImageCaption>
            </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/97honda-before.jpg")}></ImageCaption>
            </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/97honda-after.jpg")}></ImageCaption>
            </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/beforelexus.jpg")}></ImageCaption>
            </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/afterlexus.jpg")}></ImageCaption>
            </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/body.jpg")}></ImageCaption>
            </GridItem>
            <GridItem xs ={12} sm={6} md={3}>
                <ImageCaption img={require("assets/img/other.jpg")}></ImageCaption>
            </GridItem>
        </GridContainer>
    </div>
  );
}

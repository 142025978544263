import { title } from "assets/jss/material-kit-react.js";

const contactStyle = {
  section: {
    padding: "70px 0"
  },
  title: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "#999",
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  googleMap: {
      width: "100%",
      height: "60vh"
  },
  pin: {
      display: 'flex',
      alignItems: 'center',
      width: '180px',
      color: "black"
  },
  pinIcon: {
      fontSize: '4rem',
  },
  pinText: {
      fontSize: '1.3em'
  }
};

export default contactStyle;

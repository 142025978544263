import React from "react";

// @material-ui/core components
import Image from 'material-ui-image'


export default function ImageCaption(props) {
  return (
     <Image src={props.img}/>
  );
}
